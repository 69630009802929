import {useDocumentData} from "react-firebase-hooks/firestore";
import {SIM} from "../helpers/SIM";
import {FunctionComponent} from "react";
import {Timestamp, doc} from "@firebase/firestore";
import {db} from "../firebaseSetup";

const AccordianBody: FunctionComponent<SIM> = (sim: SIM) => {
  const [plan, planLoading] = useDocumentData(
      doc(db, "plans/"+sim.planId)
  );

  const [user, userLoading] = useDocumentData(
      doc(db, "users/"+sim.userId)
  );

  const dateToString = (date: Timestamp):string => {
    return new Date(date.seconds*1000).toLocaleString("en-GB",
        {
          timeZone: "UTC",
          dateStyle: "short",
        });
  };

  const loadingText ="Loading ...";

  return (
    <div className="">
      <div className="grid grid-cols-3">
        <div><b>Plan:</b> {planLoading? loadingText : plan?.name}</div>
        <div><b>Created At:</b> {dateToString(sim.createdAt)}</div>
        <div><b>Phone number:</b>{sim.number}</div>
      </div>
      <div className="grid grid-cols-4 auto-cols-max">
        <div className="grid-span-1">
          <h3>User details</h3>
          <p>Username: {userLoading? loadingText :user?.username}</p>
          <p>Purchase Date: {sim.purchaseDate.toString()}</p>
        </div>
        <div className="grid-span-3 ">
          <h3>SIM activation </h3>
          <p>Code: {sim.activationCode}</p>
          <p>URL: {sim.activationURL}</p>
        </div>
      </div>
    </div>);
};

export default AccordianBody;
