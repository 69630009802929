import {FunctionComponent} from "react";
import {Button} from "@mui/material";
import Sidebar from "../components/Sidebar";
import PlanTable from "../components/PlanTable";
import OrdersTable from "../components/OrdersTable";
import Header from "../components/Header";

import {db, functions} from "../firebaseSetup";
import {collection, doc} from "@firebase/firestore";
import {useLocation} from "react-router";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {httpsCallable} from "@firebase/functions";

const UserDetails: FunctionComponent = () => {
  const {state} = useLocation();
  const userCol = collection(db, "users");
  const userRef = doc(userCol, state.userId);
  const [user, loading, error] = useDocumentData(userRef);

  const makeAdmin = ()=>{
    const grantFunc = httpsCallable(functions, "grantAdmin");
    grantFunc({userId: state.userId});
  };

  return (
    <div className="relative bg-color w-full h-screen flex flex-col
    items-center justify-start items-stretch">
      <div className="self-stretch relative
      shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] box-border
       border-[1px] border-solid border-border-color
       items-stretch f-full">
        <Header />
      </div>
      <div id="1" className="self-stretch flex flex-row
      items-start justify-center f-full">
        <Sidebar
          propBoxShadow="unset"
          propOverflow="hidden"
          propTextShadow="unset"
        />
        <main className="
        shadow-[0px_0px_0px_4px_rgba(255,_255,_255,_0.01)]
         w-3/4 text-left f-full
         text-black font-roboto">
          {error && <strong>Error: {JSON.stringify(error)}</strong>}
          {loading && <span>Document: Loading...</span>}
          <div className="relative flex justify-between p-10 h-full">
            <div className="order-first">
              Name: {user?.basicInfo?.fulName || ""}
            </div>
            <div className="">
              Email: {user?.username|| ""}
            </div>
            <div className="">
              Currency: {user?.settings?.currency || "USD $"}
            </div>
            <Button
              onClick={makeAdmin}
              variant="contained"
              color="primary"
              size="large"
            >
              Grant Admin
            </Button>
          </div>
          <div className="grid grid-cols-2 gap-4 h-full">
            <OrdersTable userId={state.userId}/>
            <PlanTable userId={state.userId}/>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserDetails;
