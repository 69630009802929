import {FunctionComponent, useMemo} from "react";
import {Link} from "react-router-dom";
import CSS, {Property} from "csstype";

type SidebarType = {
  /** Style props */
  propBoxShadow?: Property.BoxShadow;
  propOverflow?: Property.Overflow;
  propTextShadow?: Property.TextShadow;
};

const Sidebar: FunctionComponent<SidebarType> = ({
  propBoxShadow,
  propOverflow,
}) => {
  const leftSectionStyle: CSS.Properties = useMemo(() => {
    return {
      boxShadow: propBoxShadow,
      overflow: propOverflow,
    };
  }, [propBoxShadow, propOverflow]);

  return (
    <aside
      className="shadow-[0px_0px_0px_4px_rgba(255,_255,_255,_0.01)]
      flex flex-col py-[30px] px-[23px] items-start justify-start
      text-left text-base text-black font-roboto"
      id="Left"
      style={leftSectionStyle}
    >
      <Link className="cursor-pointer [text-decoration:none] relative
        tracking-[0.4px] leading-[24px] text-[inherit]"
      to="/SIM">
        SIMs
      </Link>
      <Link
        className="cursor-pointer [text-decoration:none] relative
        tracking-[0.4px] leading-[24px] text-[inherit]"
        to="/main"
      >
        Users
      </Link>
    </aside>
  );
};

export default Sidebar;
