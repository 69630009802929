import {FunctionComponent} from "react";
import {useLocation} from "react-router-dom";
import {
  DocumentData,
  Query,
  collection,
  query,
  where,
} from "firebase/firestore";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import UserSearchLineItem from "../components/UserSearchLineItem";

import {useCollectionData} from "react-firebase-hooks/firestore";
import {db} from "../firebaseSetup";

const Main: FunctionComponent = () => {
  const {state} = useLocation();

  const c = collection(db, "users");
  let q; Query<DocumentData>;
  if (state != null && state.username != null) {
    q = query(c, where("username", "==", state.username));
  } else {
    q = query(c);
  }
  const [users, loading] = useCollectionData(q);

  const renderUsers= () =>{
    if (loading) {
      return (<div>Loading ....</div>);
    } else if (users != undefined) {
      return (<div className="self-stretch flex-1 overflow-hidden
      flex flex-col  py-[109px] px-[93px] items-start justify-items-stretch">
        {users.map((u) => (
          <UserSearchLineItem
            email={u.basicInfo?.fullName}
            userName={u.username}
            uid={u.id}/>))}
      </div>);
    }
  };
  return (
    <div className="relative bg-color w-full h-screen
    overflow-hidden flex flex-col items-center
    justify-center overflow-hidden">
      <div className="self-stretch relative
      shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] box-border
       h-[99px] shrink-0 border-[1px] border-solid
       border-border-color">
        <Header/>
      </div>
      <div className="self-stretch flex-1 bg-color flex
      flex-row items-start justify-start overflow-hidden">
        <Sidebar
          propBoxShadow="unset"
          propOverflow="hidden"
          propTextShadow="unset"
        />
        {renderUsers()}
      </div>
    </div>
  );
};

export default Main;
