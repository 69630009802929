import {FunctionComponent, useState} from "react";
import {Button, Icon, TextField} from "@mui/material";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebaseSetup";
import {useNavigate} from "react-router-dom";

const LoginFormButtons: FunctionComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword]= useState("");
  const nav = useNavigate();

  const submitLogin = async ()=> {
    try {
      await signInWithEmailAndPassword(
          auth,
          email,
          password
      ).then((userCreds) => {
        console.log("user loged in:"+userCreds.user.email);
        nav("/main");
      }).catch((err) => {
        console.log(JSON.stringify(err));
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      if (password.length == 0 && email.length == 0) {
        return;
      }
      submitLogin();
    }
  };


  return (
    <form
      className="absolute top-[284px] left-[398px] rounded-base
      shadow-[0px_0px_0px_4px_rgba(255,_255,_255,_0.01)]
      w-[643px] h-[93px]"
      action="self"
      method="post"
      id="LoginForm"
      onKeyDown={handleKeyPress}
    >
      <Button
        className="absolute top-[36px] left-[520px]"
        variant="text"
        color="primary"
        startIcon={<Icon>person_sharp</Icon>}
        endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
        onClick={submitLogin}
      >
        Login
      </Button>
      <TextField
        className="[border:none] bg-[transparent] absolute
        top-[26px] left-[283px]"
        sx={{width: 220}}
        color="primary"
        variant="filled"
        type="password"
        label="Password"
        size="medium"
        margin="none"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        className="[border:none] bg-[transparent] absolute
        top-[26px] left-[46px]"
        sx={{width: 220}}
        color="primary"
        variant="filled"
        type="email"
        label="Email"
        size="medium"
        margin="none"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </form>
  );
};

export default LoginFormButtons;
