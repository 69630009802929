import React, {FunctionComponent, ReactNode} from "react";
import {
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {SIM as simCard} from "../helpers/SIM";
import AccordianBody from "../components/AccordianBody";
import {
  collection,
  query,
} from "@firebase/firestore";
import {db} from "../firebaseSetup";
import {useCollectionData} from "react-firebase-hooks/firestore";

const SIM: FunctionComponent = () => {
  const q = query(collection(db, "sims"));
  const [sims, simsLoading, simError] = useCollectionData(q);
  /**
   *
   * @param {SIM} s
   * @return {JSX.Element}
   */
  function acordianbuilder(s: simCard) {
    return (
      <Accordion className="relative rounded"
        sx={{width: 1000}}>
        <AccordionSummary
          expandIcon={<Icon>expand_more</Icon>}
          className="border-b-4 border-neutral-300">
          <Typography sx={{width: "33%", flexShrink: 0}}>
            ICCID:
            {s.iccid}
          </Typography>
          <Typography sx={{color: "text.secondary"}}>
            Partner: {s.partner}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <AccordianBody {...s}/>
          </Typography>
        </AccordionDetails>
      </Accordion>);
  }

  const containsElement = (sims: simCard[]| undefined): ReactNode => {
    let res: JSX.Element[] = [];
    if (simsLoading) {
      res.push((<h2>Loading SIM cards</h2>));
    } else if (simError) {
      res.push((
        <strong>Error: {JSON.stringify(simError)}</strong>
      ));
    } else if (sims == undefined || sims.length <= 0) {
      res.push((<div>'No SIM cards'</div>));
    } else {
      res = sims.map((s: simCard) => {
        return acordianbuilder(s);
      });
    }
    return res;
  };

  return (
    <div className="relative bg-color w-full flex flex-col
    items-center justify-start">
      <div className="self-stretch relative
      shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] box-border
       h-[99px] shrink-0 border-[1px] border-solid border-border-color">
        <Header />
      </div>
      <div className="self-stretch flex-1 bg-color flex flex-row
      items-start justify-center">
        <Sidebar
          propBoxShadow="0px 0px 0px 4px rgba(255, 255, 255, 0.01)"
          propTextShadow="0px 0px 0px rgba(255, 255, 255, 0.01)"
        />
        <main className="relative w-3/4
        shadow-[0px_0px_0px_4px_rgba(255,_255,_255,_0.01)]">
          <h2>SIM cards</h2>
          {containsElement(sims as simCard[])}
        </main>
      </div>
    </div>
  );
};

export default SIM;
