import {FunctionComponent, useState} from "react";
import {TextField, Button, Icon} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {auth} from "../firebaseSetup";

const Header: FunctionComponent = () => {
  const [email, setEmail] = useState<string>();
  const nav = useNavigate();

  if (auth.currentUser == null) {
    nav("/");
  }

  /**
   *
   * return
   */
  function search() {
    nav("/main", {state: {username: email}});
  }

  const logout = () => {
    console.log("logging user out");
    auth.signOut();
    nav("/");
  };

  return (
    <header className="self-stretch relative
    shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] box-border h-[99px]
     shrink-0 border-[1px] border-solid border-border-color1">
      <form
        className="absolute top-[25px] left-[504px] w-[345px]
        h-[49px]"
        method="post"
      >
        <TextField
          className="[border:none] bg-[transparent] absolute
          top-[0px] left-[0px]"
          sx={{width: 220}}
          color="primary"
          variant="standard"
          type="text"
          label="Username"
          placeholder="Username or email"
          size="medium"
          margin="none"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          className="absolute top-[13px] left-[228px]"
          variant="contained"
          color="primary"
          endIcon={<Icon>search_sharp</Icon>}
          onClick={search}
        >
          Search
        </Button>
      </form>
      <div className="absolute right-0 top-0">
        <Button
          className="inset-y-8 -inset-x-4"
          variant="contained"
          color="primary"
          onClick={logout}
        >
          Logout
        </Button>
      </div>
    </header>
  );
};

export default Header;
