import {FunctionComponent} from "react";
import LoginFormButtons from "../components/LoginFormButtons";

const Login: FunctionComponent = () => {
  return (
    <div className="relative bg-color w-full h-[1024px]">
      <LoginFormButtons />
    </div>
  );
};

export default Login;
