import {FunctionComponent} from "react";

type OrderCardType = {
    paidCurrency: string;
    total: number;
    createdDate: Date;
    status: string;
    planName: string;
    orderId: string;
};

const OrderCard: FunctionComponent<OrderCardType> = ({
  paidCurrency,
  total,
  createdDate,
  status,
  planName,
}) => {
  return (
    <tr className="bg-slate-100 hover:bg-sky-100 h-5 rounded">
      <td>{planName}</td>
      <td>
        {createdDate.toLocaleString("en-GB",
            {
              timeZone: "UTC",
              timeStyle: "short",
              dateStyle: "short",
            })}
      </td>
      <td>{status}</td>
      <td>{paidCurrency} {Math.round(total*100)/100}</td>
    </tr>
  );
};

export default OrderCard;
