import {collection, where, query, orderBy, limit} from "@firebase/firestore";
import {FunctionComponent} from "react";
import {db} from "../firebaseSetup";
import {useCollectionData} from "react-firebase-hooks/firestore";
import OrderCard from "./OrderCard";

type OrderTableUser = {
  userId: string
}

const OrdersTable: FunctionComponent<OrderTableUser> = ({userId}) => {
  const q = query(
      collection(db, "orders"),
      where("userId", "==", userId),
      limit(10),
      orderBy("createdDate", "desc")
  );
  const [orders, loading, error] = useCollectionData(q);
  const renderList = () =>{
    if (loading || error) {
      return (
        <div>
          {error && <strong>Error: {JSON.stringify(error)}</strong>}
          {loading && <span>Loading...</span>}
        </div>
      );
    } else if (orders == undefined || orders?.length == 0) {
      return (<p>No Plans for user</p>);
    } else {
      return (
        <table className="table-auto w-full border-2 border-sky-500 h-full">
          <thead>
            <th>Plan Name</th>
            <th>Date</th>
            <th>Status</th>
            <th>Total</th>
          </thead>
          <tbody>
            {orders?.map((order) => (
              <OrderCard
                orderId={order.id}
                paidCurrency={order.paidCurrency}
                total={order.total}
                createdDate={new Date(order.createdDate.seconds*1000)}
                status={order.status}
                planName={order.products[0].name}
              />
            ))}
          </tbody>
        </table>
      );
    }
  };

  return (
    <div className="col-span-1">
      <h2>Orders</h2>
      {renderList()}
    </div>
  );
};

export default OrdersTable;
