import {doc} from "@firebase/firestore";
import {FunctionComponent} from "react";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {db} from "../firebaseSetup";

type PlanCardType = {
  planId?: string;
  callRemainingQuantity?: number;
  smsRemainingQuantity?: number;
  dataRemainingQuantity?: number;
  fromDate?: Date;
  toDate?: Date;
};

const PlanCard: FunctionComponent<PlanCardType> = ({
  planId,
  callRemainingQuantity,
  smsRemainingQuantity,
  dataRemainingQuantity,
  fromDate,
  toDate,
}) => {
  const [plan] = useDocumentData(doc(db, "plans/"+planId));
  return (
    <tr
      className="bg-slate-100 hover:bg-sky-100 rounded-xl">
      <td className="p-4">{plan?.name}</td>
      <td className="p-4">{plan?.partner}</td>
      <td className="flex place-items-center space-x-2 P-4">
        <div>Call: {callRemainingQuantity},</div>
        <div>SMS: {smsRemainingQuantity},</div>
        <div>Data: {dataRemainingQuantity}Mb</div>
      </td>
      <td className="p-4">
        {fromDate?.toLocaleString("en-GB",
            {
              timeZone: "UTC",
              timeStyle: "short",
              dateStyle: "short",
            })}
      </td>
      <td>{toDate?.toLocaleString("en-GB",
          {
            timeZone: "UTC",
            timeStyle: "short",
            dateStyle: "short",
          })}
      </td>
    </tr>
  );
};

export default PlanCard;
