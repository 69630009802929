import {FunctionComponent} from "react";
import {Button, Icon} from "@mui/material";
import {useNavigate} from "react-router-dom";

type UserSearchLineItemType = {
  userName?: string;
  email?: string;
  uid?:string;
  buttonContainedToTarget?: string;
};

const UserSearchLineItem: FunctionComponent<UserSearchLineItemType> = ({
  userName,
  email,
  uid,
  buttonContainedToTarget,
}) => {
  const nav = useNavigate();

  return (
    <div className="rounded-base bg-color3 flex flex-row py-[9px]
     px-4 gap-[207px] text-left text-base
      text-black font-roboto flex justify-between w-full justify-start">
      <div className="relative tracking-[0.4px] leading-[24px] uppercase">
        {userName}
      </div>
      <div className="relative tracking-[0.4px] uppercase">
        {email}
      </div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        href=""
        onClick={()=>{
          nav("/userdetails", {state: {userId: uid}});
        }}
        endIcon={<Icon>arrow_forward_sharp</Icon>}
        target={buttonContainedToTarget}
      />
    </div>
  );
};

export default UserSearchLineItem;
