// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";
import {getStorage, connectStorageEmulator} from "firebase/storage";
import firebaseConfig from "./firebaseconfig.json";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);


// eslint-disable-next-line no-restricted-globals
if (location.hostname.includes("localhost")||
  location.hostname.includes("127.0.0.1")) {
  console.debug("connecting to emulators");
  connectFirestoreEmulator(db, "localhost", 8082);
  connectAuthEmulator(auth, "http://localhost:9099/", {disableWarnings: true});
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

export {db, auth, analytics, functions, app};
