import {FunctionComponent} from "react";
import PlanCard from "../components/PlanCard";
import {collection, limit, orderBy, query} from "@firebase/firestore";
import {db} from "../firebaseSetup";
import {useCollectionData} from "react-firebase-hooks/firestore";

type PlanTableUser = {
  userId: string
}

const PlanTable: FunctionComponent<PlanTableUser> = ({userId}) => {
  const c = collection(db, `users/${userId}/attachedPlans`);
  const q = query(c, limit(10), orderBy("info.fromDate", "desc") );

  const [plans, loading, error] = useCollectionData(q);

  const renderList = () =>{
    if (loading || error) {
      return (
        <div>
          {error && <strong>Error: {JSON.stringify(error)}</strong>}
          {loading && <span>Loading...</span>}
        </div>
      );
    } else if (plans == undefined || plans?.length == 0) {
      return (<p>No Plans for user</p>);
    } else {
      return (
        <table className="table-auto w-full h-full">
          <thead className="justify-center">
            <th>Name</th>
            <th>Patner</th>
            <th>Remaining</th>
            <th>Activation Date</th>
            <th>End Date</th>
          </thead>
          <tbody>
            {plans?.map((plan) => (
              <PlanCard
                planId={plan.planId}
                callRemainingQuantity={plan.info.consumed.call}
                smsRemainingQuantity={plan.info.consumed.sms}
                dataRemainingQuantity={plan.info.consumed.dataBundle}
                fromDate={new Date(plan.info.fromDate.seconds*1000)}
                toDate={new Date(plan.info.toDate.seconds*1000)}
              />
            ))}
          </tbody>
        </table>
      );
    }
  };

  return (
    <div className="col-span-1">
      <h2>Plans</h2>
      {renderList()}
    </div>
  );
};

export default PlanTable;
